import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const PartnersFooter = () => {
  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md={{ size: 8, offset: 2 }}><img className="img-fluid hero-opacity" height={20} src={require('../../images/slogan.svg').default} alt="Achieving Higher Standards Together" /></Col>
        </Row>
      </Container>

      <Row className="text-center">
        <Col md={{ offset: 3, size: 6 }} className="text-center">
          <Row className="justify-content-center mt-5">
            <Col md={3}>
              <a href="https://ibac.org/is-bao" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/is-bao.png').default} alt="IS-Bao Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://ibac.org/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/ibac.png').default} alt="IBAC Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://nbaa.org/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/nbaa.png').default} alt="NBAA Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://www.firesideteam.com/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/fireside.png').default} alt="Fireside Partners Logo" />
              </a>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={3}>
              <a href="https://www.aircareinternational.com/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/air-care.svg').default} alt="Air Care International Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://casiprotects.com/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/casi.png').default} alt="CASI Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://www.aircrewacademy.com/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/aircrew.png').default} alt="Advanced Aircrew Academy Logo" />
              </a>
            </Col>
            <Col md={3}>
              <a href="https://apstraining.com/" target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={require('../../assets/img/partners/APS.svg').default} alt="Aviation Performance Solutions" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PartnersFooter;
